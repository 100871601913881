import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is an NFT launchpad?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">NFT Launchpad is a cryptocurrency platform that assists in minting & launching Non-fungible tokens (NFTs) to raise funds for NFT projects. This helps newbie startups and entrepreneurs to leave their footprints in the crypto universe.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What is NFT Launchpad Development?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">NFT Launchpad Development is the process of developing an NFT Launchpad platform that facilitates the launching and promotional activities of NFT projects. This NFT Launchpad platform also aids in minting and launching NFT initiatives, including marketing and NFT minting.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How do I create an NFT Launchpad platform?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">To create a custom launchpad for NFTs, you can approach a prominent NFT launchpad solution provider like Coinsclone to overcome the technical, security, and real-time challenges with professional expertise.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                Where can I get the best NFT Launchpad Development?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The crypto market has a wider array of options to choose the best NFT Launchpad development company. Coinsclone might be your ideal development partner if you prefer experienced NFT professionals, Top-class tech stacks, features, performance, and unparalleled guidance from experts.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                What are the benefits of creating an NFT Launchpad Platform?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">  
                  <p>Creating an NFT launchpad offers more benefits to enterprises including</p>
                  <ul>
                    <li>Boost up your NFT projects</li>
                    <li>Investment opportunities</li>
                    <li>Business Community Buildup</li>
                    <li>Access to Exclusive NFT business models</li>
                    <li>Drives potential investors to Business</li>
                    <li>Quicker Marketing and Promotion for your NFT projects</li>
                  </ul>
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>  
  )
}

export default FaqSection