import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>NFT Launchpad</span> Development</h1>
                <p className='pharagraph mb-0'>Level up your NFT projects to a global scale with a pioneer NFT launchpad development company. At Coinsclone, we develop a best-in-class NFT launchpad platform that is equipped with intellectual strategies and marketing capabilities to meet your business goals efficiently.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Grab a free demo</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/nft-launchpad/nft-launchpad-development-company.webp"
                alt="NFT Launchpad Development Company"
                className='d-none d-md-block float-end'
                width={621}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection