import React from 'react'



class Whatis extends React.Component {

  render() {

    return (
      <section className="whatis pt-0">                                                                                             
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center"><span className='bluecolor'>NFT Launchpad Development </span>- Accelerator Of NFT Projects </h2>
            <div className="text-center">
              <p className="pharagraph text-center">NFT Launchpad Development is a process of developing a platform that brings concepts to reality for new NFT projects to obtain market credibility, accessibility, and profitability. This NFT Launchpad platform also aids in minting and launching NFT initiatives, including marketing in a secure and decentralized environment. The launchpad services are just the marketing tools that accelerate the reach and use cases of Non-Fungible Tokens(NFTs) according to the trends and market demands.
              </p>
              <p className="pharagraph text-center mb-0">Coinsclone is a renowned NFT launchpad development company that offers NFT Launchpad Software to embark on the NFT Launchpad platform with top-class features to generate high revenue. We develop the NFT Launchpad platform with the help of experts & blockchain developers. Our NFT launchpad solutions are unique, reliable, and innovative to help businesses explore their potential with competitive benefits. Get ready for the digital revolution with custom NFT launchpad creation to set up a progressive flow for your NFT projects.
              </p>
            </div>  
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis