import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'

class FeatureBenefits extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h2 className="heading-h2"><span className="bluecolor">Leverage Our </span>NFT Launchpad Development Services</h2>
            <p className='text-center mw1030'>Coinsclone helps you create a custom launchpad for NFT that can fulfill the different needs of artists, creators, and investors from diverse industries. Get to know the service offerings we provide.</p>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                For Artists
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                For Influencers
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                For Games 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                For Content Creators
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                For the Music Industry
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs6')} >
                For the Film Industry
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">For Artists
                      </h3>
                      <p className="pharagraph">Our NFT launchpad development services help artists mint and trade their artworks and carry them to the right set of audiences. We enable an innovative launchpad with integration possibilities to connect the artist with NFT investors efficiently.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/nft-launchpad/artists.webp"
                      alt="For Artists"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">For Influencers
                      </h3>
                      <p className="pharagraph">We assist influencers in launching their NFT launchpad platform for converting their NFTs to monetize & benefit the users. Our NFT launchpad software solutions allow seamless trading without relying on third-party applications and platforms.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/nft-launchpad/influencers.webp"
                      alt="For Influencers"
                      width={364}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">For Games
                      </h3>
                      <p className="pharagraph">Our NFT launchpad for games allows gamers to list and trade their game assets and earn rewards for that. We prioritize custom functionalities in the launchpad platform that can speed up the buying and selling of game assets securely.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/nft-launchpad/games.webp"
                      alt="For Games"
                      width={392}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">For Content Creators</h3>
                      <p className="pharagraph">We develop NFT launchpads with exceptional privileges for content creators to market their content among global investors. Our streamlined launchpad development services can improve the NFT launchpad features to grab potential investors’ engagement.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/nft-launchpad/content-creators.webp"
                      alt="For Content Creators"
                      width={283}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">For the Music Industry
                      </h3>
                      <p className="pharagraph">NFTs in music are the medium of capitalizing with a dedicated NFT launchpad for the music industry. Our NFT launchpad development prioritizes user satisfaction with true ownership that can enable you to interact with worldwide investors easily.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/nft-launchpad/music-industry.webp"
                      alt="For the Music Industry"
                      width={320}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs6" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h3 className="heading-h4">For the Film Industry</h3>
                      <p className="pharagraph">Enable endless opportunities for fundraising with NFTs tokenized with a specific film that has a huge audience base. For filmmakers, NFT launchpad development allows them to connect with the target audience and monetize their  NFTs effortlessly.
                      </p>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/nft-launchpad/film-industry.webp"
                      alt="For the Film Industry"
                      width={335}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FeatureBenefits