import React from 'react'



class VariousBlock extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-use top-tier pt-100 secureof">
        <div className="container">
          <h2 className="main-heading">Our Top-Tier NFT Launchpad <span className='bluecolor'>Development Services</span></h2>
          <p className='text-center mw1030'>Our NFT launchpad development services are specifically curated to enable startups and enterprises to capitalize on their NFT ideas. Explore our range of service offerings herewith.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Launchpad Development Consultation
                  </h3>
                <p className="pharagraph">Get your queries answered and transform your ideas into business models with our NFT launchpad consultation services. Our professional experts are enriched with enormous technical knowledge to give you clear insights into your goals. We prioritize your business values and exposure and assist you in crafting a clear road map for a thriving NFT project.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Launchpad Design and Development
                </h3>
                <p className="pharagraph">Give your NFT launchpad platform a perfect-fitting layout design that grabs the user’s engagement easily. We prefer a client-centric NFT launchpad design and development that can elevate the appearance and activity of your launchpad. Our experienced specialists will verify the hassle-free functionality of the launchpad, thus ensuring your business credibility.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                NFT Launchpad Maintenance and Support
                </h3>
                <p className="pharagraph">Keep your launchpad platform stable and highly scalable with our dedicated NFT launchpad maintenance and support. We provide on-time maintenance and upgrade support that level the platform's capacity to face industry challenges. Our NFT development services will allow you to test productivity and business growth while prioritizing the higher-end performance of the launchpad.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Custom NFT Launchpad Development 
                </h3>
                <p className="pharagraph">Explore the possibilities of bringing your launchpad services to global NFT investors with custom NFT launchpad creation. We prefer an appropriate blockchain network that can support launchpad activities like minting, token listing, and more efficiently. Our NFT solutions are compatible with cross-chain functionality that can simplify the accessibility of the launchpad.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default VariousBlock