import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">What Makes Coinsclone The Leading <span className="bluecolor">NFT Launchpad Development Company?</span></h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/nft-launchpad/why-choose-nft-launchpad.webp"
                alt="Why Choose NFT Development Company"
                placeholder='none'
                className='mt-3 mb-3'
                width={500}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Coinsclone is a top-notch NFT launchpad development company providing first-class NFT launchpad software solutions to elevate your crypto journey to the next level.  We follow a streamlined and client-centric development procedure that amplifies your project exposure with improved productivity. To combat the increasing emergence of non-fungible tokens (NFTs), we formed a team of blockchain experts who are intimately familiar with the market.
              </p>
              <p className="pharagraph">Our NFT launchpad platforms are compatible with serving the niche of various industries and we tend to develop custom launchpads. As an NFT launchpad development company, We aim to support creators' NFT launches so that we can bring a positive impact on the crypto world. So, get your ideas consulted with our experts to develop a secure, scalable, and robust launchpad platform for NFTs.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose